
import VueRouter from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../components/Login'),
    meta: {
      needLogin: false
    }
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../components/Index'),
    redirect: '/Index/Home', // 默认跳转到 Home 页
    children: [
      {
        path: '/Home',
        name: 'Home',
        meta: {
          title: 'Home',
          needLogin: true
        },
        component: () => import('../components/Home')
      },
      {
        path: '/AdsAdvertisingManage',
        name: 'Demand ADS',
        meta: {
          title: 'Demand ADS',
          needLogin: true
        },
        component: () => import('../components/adsModule/AdsAdvertisingManage')
      },
      {
        path: '/MergeReport',
        name: 'Ad Report',
        meta: {
          title: 'Ad Report',
          needLogin: true
        },
        component: () => import('../components/adsModule/MergeReport')
      },
      {
        path: '/DdjTask',
        name: 'DDJ Task',
        meta: {
          title: 'DDJ Task',
          needLogin: true
        },
        component: () => import('../components/adsModule/DdjTask')
      },
      {
        path: '/ChannelAdvertisingAllocation',
        name: 'Mix Task',
        meta: {
          title: 'Mix Task',
          needLogin: true
        },
        component: () => import('../components/adsModule/ChannelAdvertisingAllocation')
      },
      {
        path: '/DdjChannel',
        name: 'Data Source',//DDJ Supply
        meta: {
          title: 'Data Source',
          needLogin: true
        },
        channelName: '',
        component: () => import('../components/adsModule/DdjChannel')
      },
      {
        path: '/ChannelAccountManage',
        name: 'Supply Account',
        meta: {
          title: 'Supply Account',
          needLogin: true
        },
        component: () => import('../components/adsModule/ChannelAccountManage')
      },
      {
        path: '/AdvertiserManage',
        name: 'Demand Account',
        meta: {
          title: 'Demand Account',
          needLogin: true
        },
        component: () => import('../components/adsModule/AdvertiserManage')
      },
      {
        path: '/ConversionReport',
        name: 'Conversion Report',
        meta: {
          title: 'Conversion Report',
          needLogin: true
        },
        component: () => import('../components/adsModule/ConversionReport')
      },
      {
        path: '/AfCompareReport',
        name: 'AfCompare Report',
        meta: {
          title: 'AfCompare Report',
          needLogin: true
        },
        component: () => import('../components/adsModule/AfCompareReport')
      },
      {
        path: '/Pid',
        name: 'PID',
        meta: {
          title: 'PID',
          needLogin: true
        },
        component: () => import('../components/adsModule/Pid')
      },


      {
        path: '/ChannelBudgetConfiguration',
        name: 'GEO Daily Budget Setting',
        meta: {
          title: 'GEO Daily Budget Setting',
          needLogin: true
        },
        component: () => import('../components/dspModule/ChannelBudgetConfiguration')
      },
      {
        path: '/ChannelDailyBudgetConfiguration',
        name: 'ADX Daily Budget Setting',//ADX Daily Budget
        meta: {
          title: 'ADX Daily Budget Setting',
          needLogin: true
        },
        component: () => import('../components/dspModule/ChannelDailyBudgetConfiguration')
      },
      {
        path: '/DSPAdvertisingManagement',
        name: 'DSP Ad',
        meta: {
          title: 'DSP Ad',
          needLogin: true
        },
        component: () => import('../components/dspModule/DSPAdvertisingManagement')
      },
      {
        path: '/DSPAdvertisingManagementCreative',
        name: 'DSP Ad Creative',
        meta: {
          title: 'DSP Ad Creative',
          needLogin: true
        },
        component: () => import('../components/dspModule/DSPAdvertisingManagementCreative')
      },
      {
        path: '/ChannelAdvertisingConfiguration',
        name: 'ADX Ad Setting',
        meta: {
          title: 'ADX Ad Setting',
          needLogin: true
        },
        component: () => import('../components/dspModule/ChannelAdvertisingConfiguration')
      },
      {
        path: '/ChannelNameConfiguration',
        name: 'ADX Account',
        meta: {
          title: 'ADX Account',
          needLogin: true
        },
        component: () => import('../components/dspModule/ChannelNameConfiguration')
      },
      {
        path: '/DSPDailyReport',
        name: 'DSP Daily Report',
        meta: {
          title: 'DSP Daily Report',
          needLogin: true
        },
        component: () => import('../components/dspModule/DSPDailyReport')
      },
      {
        path: '/DSPTransfer',
        name: 'DSP Transfer',
        meta: {
          title: 'DSP Transfer',
          needLogin: true
        },
        component: () => import('../components/dspModule/DSPTransfer')
      },
      {
        path: '/DSPTransferConfig',
        name: 'DSP Transfer Config',
        meta: {
          title: 'DSP Transfer Config',
          needLogin: true
        },
        component: () => import('../components/dspModule/DSPTransferConfig')
      },
      {
        path: '/EmuTask',
        name: 'EMU Task',
        meta: {
          title: 'EMU Task',
          needLogin: true
        },
        component: () => import('../components/emuModule/EmuTask')
      },
      {
        path: '/EventTask',
        name: 'Event Task',
        meta: {
          title: 'Event Task'
        },
        component: () => import('../components/emuModule/EventTask')
      },
      {
        path: '/EventConfig',
        name: 'Event Config',
        meta: {
          title: 'Event Config'
        },
        component: () => import('../components/emuModule/EventConfig')
      },
      {
        path: '/BundleInfo',
        name: 'Bundle INFO',
        meta: {
          title: 'Bundle INFO'
        },
        component: () => import('../components/emuModule/BundleInfo')
      },
      {
        path: '/DSPUserStatistics',
        name: 'ADX User',
        meta: {
          title: 'ADX User',
          needLogin: true
        },
        component: () => import('../components/dspModule/DSPUserStatistics')
      },
      {
        path: '/SystemLog',
        name: 'SystemLog',
        meta: {
          title: 'System Log',
          needLogin: true
        },
        component: () => import('../components/log/SystemLog')
      },
      {
        path: '/AdminManage',
        name: 'Admin Manage',
        meta: {
          title: 'Admin Manage',
          needLogin: true
        },
        component: () => import('../components/admin/AdminManage')
      },
      {
        path: '/UserManage',
        name: 'User Manage',
        meta: {
          title: 'User Manage',
          needLogin: true
        },
        component: () => import('../components/user/UserManage')
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


export function resetRouter () {
  router.matcher = new VueRouter({
    mode: 'history',
    routes: []
  }).matcher
}
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;